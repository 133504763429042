import React from 'react'

const Privacy = () => (
  <div>
    <p>
      ADATVÉDELMI TÁJÉKOZTATÓMINTAHONLAPHOZBármilyen adatkezelésbe is fog egy
      cég (például kezeli a honlapján megforduló látogatók személyes adatait, az
      ott regisztráltaknak hírlevelet küld, stb.), tevékenységéről előzetesen
      tájékoztatnia kell a látogatókat. Az adatvédelmi tájékoztató arra szolgál,
      hogy a lehető leg-érthetőbb formában közölje az érintettekkelhogyki, mire,
      mikor, hogyan és milyen célból használhatja fel az általuk szolgáltatott
      adatokat, valamint felvilágosítást nyújtsona jogaikról és –bizonyos
      esetekben–a jogorvoslati lehetőségeikről.Az előzetes tájékoztató tartalmát
      jogszabály határozza meg, ahogy azt is, hogy milyen tevé-kenységeket (pl.
      hírlevélküldés, direkt marketing célú levelezés, nyereményjáték
      szervezése) kell a tevékenységmegkezdése előtt bejelenteni a
      hatóságadatvédelmi nyilvántartásába.A mintában piros színnel jelölt részek
      tartalma a cég adatainak, tevékenységi körének megfe-lelően módosítandók,
      illetve amennyiben nincs rá szükség (pl. nyereményjáték, direkt mar-keting
      adatkezelés, engedélyköteles tevékenység megnevezése és az engedély száma,
      stb.), abban az esetben kitörlendő.ADATVÉDELMI TÁJÉKOZTATÓamelyben
      tájékoztatjuk Önt, mint honlapunk
      látogatóját,valamintszolgáltatásainkigénybe vevőjét társaságunk
      adatkezelési és adatvédelmi szabályairól.1.Milyen alapelveket követünk
      adatkezelésünk során?Társaságunk az adatkezelése során alábbi alapelveket
      követi:a)a személyes adatokat jogszerűen és tisztességesen, valamint az
      Önszámára átlátha-tóan kezeljük.b)a személyes adatokat csak meghatározott,
      egyértelmű és jogszerű célból gyűjtjük és azokat nem kezeljük a célokkal
      össze nem egyeztethető módon.c)az általunk gyűjtött és kezelt személyes
      adatok az adatkezelés céljai szempontjából megfelelőek és relevánsak,
      valamint csak a szükségesre korlátozódnak.d)Társaságunk minden észszerű
      intézkedést megtesz annak érdekében, hogy az álta-lunk kezelt adatok
      pontosak és szükség esetén naprakészek legyenek, apontatlan személyes
      adatokat haladéktalanul töröljük vagy helyesbítjük.e)a személyes adatokat
      olyan formában tároljuk, hogy Öncsak a személyes adatok ke-zelése
      céljainak eléréséhez szükséges ideig legyen azonosítható.f)megfelelő
      technikai és szervezési intézkedések alkalmazásával biztosítjuk a
      személyes adatok megfelelő biztonságát az adatok jogosulatlan vagy
      jogellenes kezelésével, vé-letlen elvesztésével, megsemmisítésével vagy
      károsodásával szemben.
    </p>
  </div>
)

export default Privacy
